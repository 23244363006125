import { default as a3sGVxp2PR9Meta } from "/portal/pages/-/a.vue?macro=true";
import { default as contactsypeLyo76aYMeta } from "/portal/pages/contacts.vue?macro=true";
import { default as _91id_937dp57Tt5d6Meta } from "/portal/pages/events/[id].vue?macro=true";
import { default as indexpmR6w0QW7QMeta } from "/portal/pages/events/index.vue?macro=true";
import { default as indexisHvXj8ZZwMeta } from "/portal/pages/index.vue?macro=true";
import { default as login5Hq1W7FoXXMeta } from "/portal/pages/login.vue?macro=true";
import { default as orderbOWSOysLZXMeta } from "/portal/pages/order.vue?macro=true";
import { default as _91id_93gDb5YFziB9Meta } from "/portal/pages/products/category/[id].vue?macro=true";
import { default as indexW25pVDQnVyMeta } from "/portal/pages/products/category/index.vue?macro=true";
import { default as _91id_93p4UEmxMMVAMeta } from "/portal/pages/products/category/product/[id].vue?macro=true";
import { default as resellerwyethm218IMeta } from "/portal/pages/reseller.vue?macro=true";
export default [
  {
    name: a3sGVxp2PR9Meta?.name ?? "--a",
    path: a3sGVxp2PR9Meta?.path ?? "/-/a",
    meta: a3sGVxp2PR9Meta || {},
    alias: a3sGVxp2PR9Meta?.alias || [],
    redirect: a3sGVxp2PR9Meta?.redirect || undefined,
    component: () => import("/portal/pages/-/a.vue").then(m => m.default || m)
  },
  {
    name: contactsypeLyo76aYMeta?.name ?? "contacts",
    path: contactsypeLyo76aYMeta?.path ?? "/contacts",
    meta: contactsypeLyo76aYMeta || {},
    alias: contactsypeLyo76aYMeta?.alias || [],
    redirect: contactsypeLyo76aYMeta?.redirect || undefined,
    component: () => import("/portal/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: _91id_937dp57Tt5d6Meta?.name ?? "events-id",
    path: _91id_937dp57Tt5d6Meta?.path ?? "/events/:id()",
    meta: _91id_937dp57Tt5d6Meta || {},
    alias: _91id_937dp57Tt5d6Meta?.alias || [],
    redirect: _91id_937dp57Tt5d6Meta?.redirect || undefined,
    component: () => import("/portal/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpmR6w0QW7QMeta?.name ?? "events",
    path: indexpmR6w0QW7QMeta?.path ?? "/events",
    meta: indexpmR6w0QW7QMeta || {},
    alias: indexpmR6w0QW7QMeta?.alias || [],
    redirect: indexpmR6w0QW7QMeta?.redirect || undefined,
    component: () => import("/portal/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexisHvXj8ZZwMeta?.name ?? "index",
    path: indexisHvXj8ZZwMeta?.path ?? "/",
    meta: indexisHvXj8ZZwMeta || {},
    alias: indexisHvXj8ZZwMeta?.alias || [],
    redirect: indexisHvXj8ZZwMeta?.redirect || undefined,
    component: () => import("/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login5Hq1W7FoXXMeta?.name ?? "login",
    path: login5Hq1W7FoXXMeta?.path ?? "/login",
    meta: login5Hq1W7FoXXMeta || {},
    alias: login5Hq1W7FoXXMeta?.alias || [],
    redirect: login5Hq1W7FoXXMeta?.redirect || undefined,
    component: () => import("/portal/pages/login.vue").then(m => m.default || m)
  },
  {
    name: orderbOWSOysLZXMeta?.name ?? "order",
    path: orderbOWSOysLZXMeta?.path ?? "/order",
    meta: orderbOWSOysLZXMeta || {},
    alias: orderbOWSOysLZXMeta?.alias || [],
    redirect: orderbOWSOysLZXMeta?.redirect || undefined,
    component: () => import("/portal/pages/order.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gDb5YFziB9Meta?.name ?? "products-category-id",
    path: _91id_93gDb5YFziB9Meta?.path ?? "/products/category/:id()",
    meta: _91id_93gDb5YFziB9Meta || {},
    alias: _91id_93gDb5YFziB9Meta?.alias || [],
    redirect: _91id_93gDb5YFziB9Meta?.redirect || undefined,
    component: () => import("/portal/pages/products/category/[id].vue").then(m => m.default || m)
  },
  {
    name: indexW25pVDQnVyMeta?.name ?? "products-category",
    path: indexW25pVDQnVyMeta?.path ?? "/products/category",
    meta: indexW25pVDQnVyMeta || {},
    alias: indexW25pVDQnVyMeta?.alias || [],
    redirect: indexW25pVDQnVyMeta?.redirect || undefined,
    component: () => import("/portal/pages/products/category/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p4UEmxMMVAMeta?.name ?? "products-category-product-id",
    path: _91id_93p4UEmxMMVAMeta?.path ?? "/products/category/product/:id()",
    meta: _91id_93p4UEmxMMVAMeta || {},
    alias: _91id_93p4UEmxMMVAMeta?.alias || [],
    redirect: _91id_93p4UEmxMMVAMeta?.redirect || undefined,
    component: () => import("/portal/pages/products/category/product/[id].vue").then(m => m.default || m)
  },
  {
    name: resellerwyethm218IMeta?.name ?? "reseller",
    path: resellerwyethm218IMeta?.path ?? "/reseller",
    meta: resellerwyethm218IMeta || {},
    alias: resellerwyethm218IMeta?.alias || [],
    redirect: resellerwyethm218IMeta?.redirect || undefined,
    component: () => import("/portal/pages/reseller.vue").then(m => m.default || m)
  }
]